import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRolePath, getMemberPath, getPermissionSetPath } from './AppRoutes';
import SideMenuSubList from './SideMenuSublist';
import { SideMenuRouteProps } from './SideMenuRoute';
import GroupIcon from '@mui/icons-material/Group';
import KeyIcon from '@mui/icons-material/Key';
import RoleIcon from '@mui/icons-material/Rule';
import AbilityContext from '../context/AbilityContext';
import { useAbility } from '@casl/react';
import { Resource } from '../tools/resourceTools';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const ROUTES = [
  {
    resource: Resource.MEMBER,
    path: getMemberPath({ mode: 'list' }),
    Icon: GroupIcon
  },
  {
    resource: Resource.ROLE,
    path: getRolePath({ mode: 'list' }),
    Icon: RoleIcon
  },
  {
    resource: 'permission',
    path: getPermissionSetPath({ mode: 'list' }),
    Icon: KeyIcon
  }
];

const AdministrationList = () => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const [routes, setRoutes] = useState<SideMenuRouteProps[]>([]);

  useEffect(() => {
    const routes = ROUTES.filter((route) => ability.can('get', route.resource)).map((route) => {
      return {
        ...route,
        name: t(`${route.resource}.title`)
      };
    });
    setRoutes(routes);
  }, [ability, t]);

  return routes.length ? (
    <SideMenuSubList title={t('sidemenu.administration')} paths={routes} icon={<AdminPanelSettingsIcon />} />
  ) : (
    <></>
  );
};

export default AdministrationList;
