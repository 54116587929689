const dico = {
  title: 'ORKA console',
  action: {
    logout: 'Me déconnecter',
    create: 'Créer',
    update: 'Modifier',
    delete: 'Supprimer',
    close: 'Fermer',
    save: 'Enregistrer',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    add: 'Ajouter',
    addValue: 'Ajouter {{value}}',
  },
  status: {
    loading: 'Chargement',
  },
  error: {
    notAllowed: "Accès non autorisé",
    forbidden: "Vous n'avez pas le droit de faire cet action, veuillez rafraichir votre page",
    unknown: "An error occurs, please retry later",
  },
  member: {
    title: 'Membres',
    email: 'Email',
    lastName: 'Nom',
    firstName: 'Prénom',
    phone: 'Téléphone',
    country: 'Pays',
    language: 'Langue',
    languageValues: {
      "fr": "Français",
      "en": "English",
    },
    roles: 'Roles',
    pronouns: "Pronoms",
    pronounsValues: {
      "he/him": 'Il/Lui',
      "she/her": 'Elle/Elle',
      "they/them": "Iel/Iel"
    },

    create: 'Créer Membre',
    update: 'Modifier Membre',
    delete: 'Supprimer Membre',

    confirmDelete: 'Etes-vous sûr de vouloir supprimer le membre <b>{{name}}</b>?',

    success: {
      create: "Le membre {{name}} a bien été créé.",
      update: "Le membre {{name}} a bien été mis à jour.",
      delete: "Le membre a bien été supprimé."
    },
    error: {
      notUnique: "Un membre avec cet email {{email}} existe déjà, veuillez le changer.",
      phone: "Le numéro de téléphone doit commencer par \"+\" suivi du préfix du pays et du numéro",
      email: "L'email n'est pas valide",
    }
  },
  common: {
    noValue: "Pas de donnée",
    empty: "[vide]"
  },
  role: {
    title: 'Roles',
    name: 'Nom',
    description: 'Description',
    permissionSets: 'Liste des permissions',
    empty: "Aucun",

    create: 'Créer Role',
    update: 'Modifier Role',
    delete: 'Supprimer Role',

    linkedMembers: "Membres liés",
    members_zero: "{{count}} membre",
    members_one: "{{count}} membre",
    members_other: "{{count}} membres",

    success: {
      create: "Le role {{name}} a bien été créé.",
      update: "Le role {{name}} a bien été mis à jour.",
      delete: "Le role a bien été supprimé."
    },
    confirmDelete_zero: 'Etes-vous sûr de vouloir supprimer le role <b>{{name}}</b> ?',
    confirmDelete_one: 'Etes-vous sûr de vouloir supprimer le role <b>{{name}}</b> ? <br/><br/>{{count}} membre est associé à ce role et perdra les permissions liées',
    confirmDelete_other: 'Etes-vous sûr de vouloir supprimer le role <b>{{name}}</b> ? <br/><br/>{{count}} membres sont associés à ce role et perdront les permissions liées',

  },
  "permission-set": {
    title: 'PermissionSets',
    name: 'Nom',
    description: 'Description',
    permissions: 'Permissions',
    empty: "Aucun",

    addPermission: "Cliquez ici pour ajouter une nouvelle permission",

    create: 'Créer PermissionSet',
    update: 'Modifier PermissionSet',
    delete: 'Supprimer PermissionSet',

    linkedRoles: "Roles liés",
    roles_zero: "{{count}} role",
    roles_one: "{{count}} role",
    roles_other: "{{count}} roles",

    success: {
      create: "Le permissionSet {{name}} a bien été créé.",
      update: "Le permissionSet {{name}} a bien été mis à jour.",
      delete: "Le permissionSet a bien été supprimé."
    },
    confirmDelete_zero: 'Etes-vous sûr de vouloir supprimer le permissionSet <b>{{name}}</b> ?',
    confirmDelete_one: 'Etes-vous sûr de vouloir supprimer le permissionSet <b>{{name}}</b> ? <br/><br/>{{count}} role est associé à ce permissionSet et perdra les permissions liées',
    confirmDelete_other: 'Etes-vous sûr de vouloir supprimer le permissionSet <b>{{name}}</b> ? <br/><br/>{{count}} roles sont associés à ce permissionSet et perdront les permissions liées',

  },
  site: {
    title: 'Sites de Production',
    country: 'Pays',
    city: 'Ville',
    timezone: 'Timezone',
    id: "ID",
    operatedBy: "Opéré par",
    other: "Autre",

    create: 'Créer un site de production',
    update: 'Modifier un site de production',
    delete: 'Supprimer un site de production',

    confirmDelete: 'Etes-vous sûr de vouloir supprimer le site de production <b>{{id}}</b>?',

    success: {
      create: "Le site de production {{id}} a bien été créé.",
      update: "Le site de production {{id}} a bien été mis à jour.",
      delete: "Le site de production a bien été supprimé."
    },
    error: {
      notUnique: "Le site de production {{id}} existe déjà, veuillez changer l'ID.",
    }
  },
  "production-line": {
    title: 'Lignes de Production',

    name: "Nom",
    order: "Ordre",
    site: "Site de production",
    productionUnit: "Unité de production",


    create: 'Créer une ligne de production',
    update: 'Modifier une ligne de production',
    delete: 'Supprimer une ligne de production',

    confirmDelete: 'Etes-vous sûr de vouloir supprimer la ligne de production <b>{{name}}</b>?',

    success: {
      create: "La ligne de production {{name}} a bien été créée.",
      update: "La ligne de production {{name}} a bien été mise à jour.",
      delete: "La ligne de production a bien été supprimée."
    },
    error: {
    }
  },
  "production-unit": {
    title: 'Unités de Production',
    name: 'Nom',
    site: 'Site de production',
    type: 'Type',
    typeValues: {
      audio: "Audio",
      video: "Vidéo",
      film: "Film",
    },
    lineCount: '# Lignes',
    associate: {
      robot: "Associer/Dissocier un Robot",
      workStation: "Associer/Dissocier une Workstation"
    },
    saveAsset: {
      robot: "Enregistrer le Robot",
      workStation: "Enregistrer la Workstation"
    },
    removeAsset: {
      robot: "Dissocier le Robot",
      workStation: "Dissocier la Workstation"
    },
    showAll: {
      robot: "Inclure les Robots associés à une unité de production",
      workStation: "Inclure les Workstations associées à une unité de production",
    },

    create: 'Créer une unité de production',
    update: 'Modifier une unité de production',
    delete: 'Supprimer une unité de production',

    details: "Détails",

    confirmDelete: 'Etes-vous sûr de vouloir supprimer l\'unité de production <b>{{name}}</b>?',

    success: {
      create: "L'unité de production {{name}} a bien été créée.",
      update: "L'unité de production {{name}} a bien été mise à jour.",
      delete: "L'unité de production a bien été supprimée."
    },
    error: {
    }
  },
  "asset": {
    title: 'Assets',

    site: 'Site de production',
    brand: 'Marque',
    model: 'Modèle',
    serial: 'Numéro de série',
    port: 'Port',
    comments: 'Commentaires',
    type: 'Type',
    category: 'Categorie',
    status: 'Statut',
    assignedToProductionLine: 'Ligne de production',
    assignedToProductionUnit: 'Unité de production',
    assignedToAsset: 'Asset',
    associated: 'Associé',
    notAssociated: "N'est pas associé",

    remoteControlProtocol: "Remote Control Protocol",
    isrPort: "Port ISR",
    applicationProtocols: "Application Protocols",
    firewireDeviceId: "Firewire Device Id",
    ethernetMacAddress: "Ethernet Mac Address",
    ports: "Ports",
    ingestChannels: "Ingest Channels",
    head: "Head",


    locationReadOnly: "Seuls les assets inactif peuvent changer de localisation",

    reference: {
      inventoryId: 'Inventaire',
      productionId: 'Id de production',
      custom1: 'Personalisé 1',
      custom2: 'Personalisé 2',
      custom3: 'Personalisé 3',
    },

    sections: {
      reference: "Références",
      characteristic: "Caractéristiques",
      location: "Localisation",
      properties: "Propriétés",
      status: "Statut",
      components: "Composants",
    },

    create: 'Créer un Asset',
    update: 'Modifier un Asset',
    delete: 'Supprimer un Asset',

    confirmDelete: 'Etes-vous sûr de vouloir supprimer l\'<b>{{name}}</b>?',

    success: {
      create: "L'Asset {{name}} a bien été créé.",
      update: "L'Asset {{name}} a bien été mis à jour.",
      delete: "L'Asset a bien été supprimé."
    },
    error: {
      "not-associable": "{{name}} ne peut pas être associé car est déjà associé à l'unité de production {{productionUnit}}"
    }
  },

  amplify: {
    'Sign In': 'Se connnecter',
    'Sign Up': "M'enregistrer",
    'Sign Out': 'Me déconnecter',
    'Forgot Password': 'Mot de passe oublié',
    Username: 'Email',
    Password: 'Mot de passe',
    'Change Password': 'Changer mon mot de passe',
    'New Password': 'Nouveau mot de passe',
    Email: 'Email',
    'Phone Number': 'Numéro de téléphone',
    'Confirm Password': 'Confirmer le mot de passe',
    'Confirm a Code': 'Code de confirmation',
    'Confirm Sign In': 'Confirmer la connexion',
    'Confirm Sign Up': "Confirmer l'enregistrement",
    'Back to Sign In': 'Retour à la connexion',
    'Send Code': 'Envoyer un code',
    Confirm: 'Confirmer',
    'Resend a Code': 'Renvoyer le code',
    Submit: 'Soumettre',
    Skip: 'Passer',
    Verify: 'Vérifier',
    'Verify Contact': 'Vérifier le contact',
    Code: 'Code',
    'Account recovery requires verified contact information':
      'La récupération de compte nécessite des informations de contact vérifiées',
    'User does not exist': "L'utilisatuer n'existe pas",
    'User already exists': "L'utilisateur existe déjà",
    'Incorrect username or password': 'Email ou mot de passe invalide',
    'Invalid password format': 'Format de mot de passe invalide',
    'Invalid phone number format':
      'Format de numéro de téléphone non valide.\nVeuillez utiliser le format de numéro de téléphone +45678900',
    'Sign in to your account': 'Connectez-vous à votre compte',
    'Forget your password? ': 'Mot de passe oublié ?',
    'Reset password': 'Réinitialiser le mot de passe',
    'No account? ': 'Pas encore inscrit ? ',
    'Create account': 'Créer un compte',
    'Create Account': 'Créer un compte',
    'Have an account? ': 'Vous avez déjà un compte ? ',
    'Sign in': "S'identifier",
    'Create a new account': 'Créer un nouveau compte',
    'Reset your password': 'Réinitialisez votre mot de passe',
    'Enter your username': 'Entrer votre email',
    'Enter your password': 'Entrer votre mot de passe',
    'An account with the given email already exists.':
      "Un compte avec l'adresse e-mail indiquée existe déjà.",
    'Username cannot be empty': "L'email doi être fourni.",
    Given_name: 'Prénom',
    Name: 'Nom',
    Phone_number: 'Numéro de téléphone',
    'Forgot your password? ': 'Mot de passe oublié ? ',
    'Enter your email': 'Entrer votre Email',
  },
  datagrid: {
    // Custom translations
    'filters': 'Filtres',
    // Column Options'dragHeaderToGroup': 'Drag header to group',
    'sortAsc': 'Tri croissant',
    'sortDesc': 'Tri déccroissant',
    'unsort': 'Effacer tri',
    'columns': 'Colonnes',
    'showFilteringRow': 'Afficher filtres',
    'hideFilteringRow': 'Cacher filtres',

    // Filtering options
    'contains': 'Contient',
    'eq': 'Égal',
    'startsWith': 'Commence par',
    'endsWith': 'Termine par',
    'enable': 'Activer',
    'disable': 'Désactiver',
    'clear': 'Éffacer',
    'clearAll': 'Éffacer tout',

    // Footer
    'pageText': 'Page',
    'perPageText': 'Résultats par page',
    'showingText': 'Afficher',
    'ofText': ' sur ',
  },
  sidemenu: {
    // Custom translations
    'home': 'Acceuil',
    'administration': 'Administration',
    'configuration': 'Configuration',
    'members': 'Membres',
  },
  permission: {
    'title': 'Permissions',
    'empty': 'Aucune',
    'action': {
      "manage": "Tout",
      "put": "Modifier",
      "post": "Créer",
      "delete": "Supprimer",
      "get": "Lire",
    },
  },
  form: {
    error: {
      required: "Ce champ est obligatoire",
      "not-number": "Ce champ doit être un nombre",
    }
  }
};
export default dico;
